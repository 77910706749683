import React from 'react';

const BlogPost = (props) => {
	return (
		<div className="content blog-post">
			<img src={props.post.img} alt="zdjęcie na blogu" />
			<div>
				<p>{props.post.text}</p>
			</div>
		</div>
	);
};

export default BlogPost;
