import React from 'react';
import Layout from '../../components/layout';
import TitleHeader from '../../components/TitleHeader';
import BlogPost from '../../components/BlogPost';
import Licowki from '../../images/blog/licowki.png'



const Impact = (props) => {
	return (
		<Layout {...props}>
			<TitleHeader title="Licówki – sposób na piękne zęby – jak wygląda proces ich zmiany?" />
            <BlogPost post={{img: Licowki, text: article.text}}/>
		</Layout>
	);
};

const article = {
	text: `
    Marzysz o idealnym uśmiechu? Twoje zęby są przebarwione, występują pomiędzy nimi szpary lub maja pewne nierówności? Może po prostu chcesz mieć gwiazdorski uśmiech? Problemy te można rozwiązać licówkami porcelanowymi, które sprawią, że Twój uśmiech będzie lśnił.
    Zmiana – krok po kroku
    W pierwszej kolejności na wizycie konsultacyjnej ustalamy czy nie istnieją przeciwskazania do wykonania licówek. Takim przeciwskazaniem jest na przykład bruksizm. To stan w którym mimowolnie zaciskasz swoje zęby ścierając je i niszcząc. W takiej sytuacji licówki też ulegną zniszczeniu. Konieczna jest dogłębna diagnostyka i leczenie. Zdarza się również, że ustawienie zębów bądź ich nierówności są na tyle duże, że w pierwszym etapie konieczna jest ortodontyczna korekta ustawienia zębów. Całość procesu jest wtedy dłuższa jednak efekt końcowy jest stabilny i długotrwały. Jeżeli Twoje zęby są zdrowe a warunki optymalne do wykonania licówek przystępujemy do zaplanowania estetyki Twoich „nowych” zębów. Na podstawie wycisku (odwzorowania kształtu zębów w plastycznej masie) lub skanu (reprodukcji kształtu zębów w trójwymiarowym obrazie za pomocą skanera wewnątrzustnego) projektujemy nowy kształt Twoich zębów. Dla zaplanowania estetyki potrzebujemy Twoich zdjęć w uśmiechu oraz w normalnym - spoczynkowym ułożeniu ust. Niejednokrotnie nagramy również film gdy nam coś opowiadasz i się śmiejesz. Wygląd Twojego uśmiechu to nie tylko zęby ale również to jak położone są względem dziąseł, jak współgrają z policzkami i wargami gdy mówisz lub się śmiejesz. Tego nie odda nawet najlepsze statyczne zdjęcie. Po poznaniu „bazy” dostosowujemy kształt nowych zębów do Twojej płci, owalu twarzy a nawet charakteru. Trudno bowiem wyobrazić sobie rosłego mężczyznę – typ „Rambo” z małymi owalnymi ząbkami – prawda? Jeżeli jeszcze macie wątpliwości odsyłam do słynnej sceny z frazą : „I’ll be back!”.
    Na podstawie naszych uwag technik przygotowuje model kształtu przyszłych zębów. Za pomocą silikonowego „klucza” przenosimy ten kształt do ust wklejając na zęby specjalny materiał, który będzie odwzorowywał kształt przyszłych zębów. Tym sposobem bez jakiejkolwiek ingerencji we własne zęby można zaprezentować ich przyszły kształt. Tu następuje moment konfrontacji planu z rzeczywistością i faktycznymi oczekiwaniami zainteresowanego. To etap by zastanowić się czy taki kształt jest odpowiedni, czy jednak coś należy zmienić. Jeżeli kształt jest odpowiedni przechodzimy do etapy przygotowania zębów. Konieczne jest ich opracowanie, oszlifowanie zwykle w bardzo minimalnym zakresie maksymalnie to około 0,5 mm grubości szkliwa. Kształt przygotowanych zębów rejestrujemy skanując je lub robiąc wycisk. Po tym zabiegu wykonywanym w znieczuleniu zęby pokrywamy licówkami tymczasowymi. Technik na przygotowanie kompletu licówek w łuku górnym czyli na przykład od piątki do piątki potrzebuje około 7-10 dni roboczych. Nie będziesz jednak cierpiał z powodów estetycznych ponieważ Twoje zęby będą odbudowane tymczasowo. Po otrzymaniu gotowych licówek od technika ściągamy tymczasowa pracę oceniając czy nowe licówki „pasują” w kwestii koloru, kształtu, wykonania technicznego. Zdarza się, że czasem konieczna jest korekta koloru bądź kształtu. Wtedy licówki odsyłamy do technika na maksymalnie około 2-3 dni aby dokonał stosownej korekty. Gotowe licówki osadzamy/przyklejamy do zębów. Po całym procesie sprawdzamy czy wszystko dokładnie „pasuje” w zgryzie. Pozostaje jeszcze tylko pamiątkowe zdjęcie oraz przypomnienie o terminie wizyty kontrolnej. Już możesz cieszyć się nowym uśmiechem! :)))
    `
}

export default Impact;
